/* FinkHeavy */
@font-face {
  font-family: 'FinkHeavy';
  src: local('FinkHeavy'), url(/static/media/FinkHeavy.d4739ad6.woff2) format('woff2'),
    url(/static/media/FinkHeavy.c0d9aa49.woff) format('woff'),
    url(/static/media/FinkHeavy.f4576cda.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* SF Pro Rounded */
@font-face {
  font-family: 'SF Pro Rounded Black';
  src: local('SF Pro Rounded Black'), url(/static/media/SF-Pro-Rounded-Black.1034d2b8.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: local('SF Pro Rounded'), url(/static/media/SF-Pro-Rounded-Bold.93abe5ce.woff2) format('woff2'),
    url(/static/media/SF-Pro-Rounded-Bold.ef233122.woff) format('woff'),
    url(/static/media/SF-Pro-Rounded-Bold.7ae8b4de.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Barbieri-Regular */
@font-face {
  font-family: 'Barbieri-Regular';
  src: local('Barbieri-Regular'), url(/static/media/Barbieri-Regular.a6ee0efc.woff2) format('woff2'),
    url(/static/media/Barbieri-Regular.f8d66f4d.woff) format('woff'),
    url(/static/media/Barbieri-Regular.43b20de6.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Impact */
@font-face {
  font-family: 'Impact';
  src: local('Impact'), url(/static/media/Impact.3d12630c.woff2) format('woff2'),
    url(/static/media/Impact.3effb631.woff) format('woff');
  /* url('./art/fonts/Impact/Impact.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
}

/* SF Pro Display - 400*/
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url(/static/media/SFProDisplay-Regular.68dd00a1.woff2) format('woff2'),
    url(/static/media/SFProDisplay-Regular.2ee6eb03.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* SF Pro Display - 500 */
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url(/static/media/SFProDisplay-Medium.062b6188.woff2) format('woff2'),
    url(/static/media/SFProDisplay-Medium.d6311c23.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* SF Pro Display - 600 */
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url(/static/media/SFProDisplay-Semibold.81f78be5.woff2) format('woff2'),
    url(/static/media/SFProDisplay-Semibold.914b3978.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant Garamond';
  src: local('Cormorant Garamond SemiBold'), local('CormorantGaramond-SemiBold'),
    url(/static/media/CormorantGaramond-SemiBold.e475c122.woff2) format('woff2'),
    url(/static/media/CormorantGaramond-SemiBold.c57e91b4.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html {
  height: 100%;
  /* background: linear-gradient(162.46deg, #fbe9f5 0%, #e7edfb 100%); */
}

body {
  margin: 0;
  display: -webkit-flex;
  display: flex;
  min-height: 100%;
  width: 100%;
  background-color: #1c1c1c;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}

html::-webkit-scrollbar {
  display: none;
  background: transparent;
  width: 0;
}

/* desktop breakpoint */
@media screen and (min-width: 960px) {
  body[style] {
    /* fix open menu scrollbar bug */
    overflow-y: auto !important;
  }
}

/* disable image highlight when click or touch */
img,
.no-selection {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img::selection,
.no-selection::-moz-selection {
  color: transparent;
  background-color: transparent;
}

/* need add fix width or max-width for working */
.text-overflow {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#root {
  display: block;
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 100%;
  /* height: 100vh; */
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#root::-webkit-scrollbar {
  display: none;
  background: transparent;
  width: 0;
}

div {
  display: flex;
  display: -webkit-flex;
}

b {
  font-weight: 900;
}

p {
  margin: 0;
}

/* mobile text outline */
.mobile-outline {
  /* -webkit-text-stroke: 0.5px black; */
  /* text-stroke: 0.5px black; */
  text-shadow: -2px 2px 3px black;
}

@media screen and (min-width: 960px) {
  .mobile-outline {
    /* -webkit-text-stroke: initial; */
    /* text-stroke: initial; */
    text-shadow: none;
  }
}

/* if browser don't support space-evenly, works space-around (IE specific) */
.resultsBarsContainer,
.resultsVotesTitlesContainer {
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

/* disable box-shadow on <Popover /> material */
.MuiPopover-paper {
  box-shadow: none !important;
}

/* styling material select dropdown */
.MuiMenu-list {
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #f3f4f5;
}

/* Phanthom plugin - centered choose wallet plugin */
.wallet-adapter-modal {
  z-index: 1301;
}

.wallet-adapter-modal-container {
  margin: auto;
}

.wallet-adapter-dropdown {
  height: auto;
}

.wallet-adapter-dropdown-list {
  right: 10%;
}

/* .wallet-adapter-button {
  width: auto !important;
  background-color: red;
} */

/*
.wallet-adapter-button > i {
  display: none;
} */

@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  87% {
    -webkit-transform: rotate(390deg);
            transform: rotate(390deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  87% {
    -webkit-transform: rotate(390deg);
            transform: rotate(390deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes appearFromTop {
  from {
    top: -100px;
  }

  to {
    top: 0;
  }
}

@keyframes appearFromTop {
  from {
    top: -100px;
  }

  to {
    top: 0;
  }
}

@-webkit-keyframes appearFromBottom {
  from {
    bottom: -200px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes appearFromBottom {
  from {
    bottom: -200px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes hideToTop {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -100px;
    opacity: 0;
  }
}

@keyframes hideToTop {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -100px;
    opacity: 0;
  }
}

@-webkit-keyframes hideToBottom {
  from {
    bottom: 0;
    opacity: 1;
  }

  to {
    bottom: -100px;
    opacity: 0;
  }
}

@keyframes hideToBottom {
  from {
    bottom: 0;
    opacity: 1;
  }

  to {
    bottom: -100px;
    opacity: 0;
  }
}

@-webkit-keyframes showAlertMobile {
  0% {
    opacity: 0;
    top: -65px;
  }

  25% {
    opacity: 1;
    top: 0;
  }

  75% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: -65px;
  }
}

@keyframes showAlertMobile {
  0% {
    opacity: 0;
    top: -65px;
  }

  25% {
    opacity: 1;
    top: 0;
  }

  75% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: -65px;
  }
}

@-webkit-keyframes showAlertTablet {
  0% {
    opacity: 0;
    top: -65px;
  }

  25% {
    opacity: 1;
    top: 0;
  }

  75% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: -65px;
  }
}

@keyframes showAlertTablet {
  0% {
    opacity: 0;
    top: -65px;
  }

  25% {
    opacity: 1;
    top: 0;
  }

  75% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: -65px;
  }
}

@-webkit-keyframes showAlertDesktop {
  0% {
    opacity: 0;
    top: 66px;
  }

  50% {
    opacity: 1;
    top: 16px;
  }

  75% {
    opacity: 1;
    top: 16px;
  }

  100% {
    opacity: 0;
    top: 66px;
  }
}

@keyframes showAlertDesktop {
  0% {
    opacity: 0;
    top: 66px;
  }

  50% {
    opacity: 1;
    top: 16px;
  }

  75% {
    opacity: 1;
    top: 16px;
  }

  100% {
    opacity: 0;
    top: 66px;
  }
}

@-webkit-keyframes rotating-360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  90% {
    -webkit-transform: rotate(390deg);
            transform: rotate(390deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotating-360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  90% {
    -webkit-transform: rotate(390deg);
            transform: rotate(390deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating-540 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  90% {
    -webkit-transform: rotate(570deg);
            transform: rotate(570deg);
  }

  100% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }
}

@keyframes rotating-540 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  90% {
    -webkit-transform: rotate(570deg);
            transform: rotate(570deg);
  }

  100% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }
}

@-webkit-keyframes disappearing {
  from {
    opacity: 1;
    pointer-events: none;
    cursor: pointer !important;
  }

  to {
    opacity: 0;
  }
}

@keyframes disappearing {
  from {
    opacity: 1;
    pointer-events: none;
    cursor: pointer !important;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes coinsFalling {
  from {
    top: -350px;
  }

  to {
    top: 50px;
  }
}

@keyframes coinsFalling {
  from {
    top: -350px;
  }

  to {
    top: 50px;
  }
}

@-webkit-keyframes change-fill {
  0% {
    fill: #f68bb6;
  }

  9% {
    fill: #e783ca;
  }

  18% {
    fill: #e577c3;
  }

  27% {
    fill: #e268bf;
  }

  36% {
    fill: #ef61c9;
  }

  45% {
    fill: #dd4fc4;
  }

  54% {
    fill: #ffd168;
  }

  63% {
    fill: #ffc74c;
  }

  72% {
    fill: #ffb860;
  }

  81% {
    fill: #ffb357;
  }

  90% {
    fill: #ffa538;
  }

  99% {
    fill: #fe9a60;
  }
}

@keyframes change-fill {
  0% {
    fill: #f68bb6;
  }

  9% {
    fill: #e783ca;
  }

  18% {
    fill: #e577c3;
  }

  27% {
    fill: #e268bf;
  }

  36% {
    fill: #ef61c9;
  }

  45% {
    fill: #dd4fc4;
  }

  54% {
    fill: #ffd168;
  }

  63% {
    fill: #ffc74c;
  }

  72% {
    fill: #ffb860;
  }

  81% {
    fill: #ffb357;
  }

  90% {
    fill: #ffa538;
  }

  99% {
    fill: #fe9a60;
  }
}

@-webkit-keyframes appearing {
  from {
    opacity: 0;
    scale: 0.2;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes appearing {
  from {
    opacity: 0;
    scale: 0.2;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@-webkit-keyframes timerRotation {
  0% {
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes timerRotation {
  0% {
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.pandacards * {
  font-family: 'SF Pro Display';
  box-sizing: border-box;
}

@-webkit-keyframes loader-default {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes loader-default {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.MuiTooltip-tooltip {
  background-color: transparent !important;
}

.rc-tooltip {
  &.rc-tooltip-zoom-appear,
  &.rc-tooltip-zoom-enter {
    opacity: 0;
  }

  &.rc-tooltip-zoom-enter,
  &.rc-tooltip-zoom-leave {
    display: block;
  }
}

.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}

.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}

.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  /* background-color: #373737; */
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

